//#region Imports

import { Injectable } from '@angular/core';
import { CreateAddressPayload } from '../../models/payloads/create-address.payload';
import { UpdateAddressPayload } from '../../models/payloads/update-address.payload';
import { AddressProxy } from '../../models/proxies/address.proxy';
import { GetManyProxy } from '../../models/proxies/base/get-many.proxy';
import { createCrudUrl, CrudRequestParams } from '../../utils/crud-options';
import { environment } from '../../../environments/environment';
import { AsyncResult } from '../../modules/http/models/async-result';
import { HttpService } from '../../modules/http/services/http.service';
import { BaseUrlInterceptor } from '../../modules/http/interceptors/base-url.interceptor';
import { Location } from 'src/app/models/interfaces/location.interface';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AddressInteractor {

  //#region Constructor

  constructor(
    private readonly httpService: HttpService,
  ) { }

  //#endregion

  //#region Methods

  public async getOne(id: number, crudOptions?: CrudRequestParams<AddressProxy>): Promise<AsyncResult<AddressProxy>> {
    const url = createCrudUrl<AddressProxy>(environment.apiKeys.address.byId.replace('{addressId}',  String(id)), crudOptions);

    return await this.httpService.get<AddressProxy>(url);
  }

  public async getAll(crudOptions?: CrudRequestParams<AddressProxy>): Promise<GetManyProxy<AddressProxy>> {
    const url = createCrudUrl<AddressProxy>(environment.apiKeys.address.base, crudOptions);

    return await this.httpService.get<AddressProxy[]>(url);
  }

  public async getCitiesRegistered(crudOptions?: CrudRequestParams<AddressProxy>): Promise<AsyncResult<string[]>> {
    const url = createCrudUrl<string>(environment.apiKeys.address.citiesRegistered);

    return await this.httpService.get<string[]>(url);
  }

  public async create(payload: CreateAddressPayload): Promise<AsyncResult<AddressProxy>> {
    const url = environment.apiKeys.address.base;

    return await this.httpService.post<AddressProxy>(url, payload);
  }

  public async update(id: number, payload: UpdateAddressPayload): Promise<AsyncResult<AddressProxy>> {
    const url = environment.apiKeys.address.byId.replace('{addressId}', String(id));

    return await this.httpService.put<AddressProxy>(url, payload);
  }

  public async getCurrentLocation(lat: number, long: number): Promise<AsyncResult<Location>> {
    const baseUrl = 'https://nominatim.openstreetmap.org/reverse?format=json&lat={lat}&lon={long}'

    const url = baseUrl.replace('{lat}', String(lat)).replace('{long}', String(long));

    return await this.httpService.get(url, {
      headers: {
        [BaseUrlInterceptor.DISABLE_HEADER]: '*',
      }
    });
  }

  //#endregion
}
