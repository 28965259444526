export namespace CustomValidators {
  export function isValidEmail(email: string): boolean {
    const regex = new RegExp('^[A-Za-z0-9\.\_\%\+\-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
    return regex.test(email);
  }

  export function isValidPassword(password: string): string | boolean {
    return password && password.trim().length >= 6;
  }

  export function isValidContent(content: string, length?: number): string | boolean {
    return (content && content !== '' && ((length && content.trim().length >= length) || !length));
  }

  export function emailStatus(email: string): any {
    const regex = new RegExp('^[A-Za-z0-9\.\_\%\+\-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
    const regexBlankSpaces = new RegExp('\\s+');
    const regexSpecialCharacters = new RegExp('[A-Za-z0-9\.\_\%\+\-\@]$');

    if (email === '')
      return { status: regex.test(email), message: "É obrigatório enviar um email" };
    else if (regexBlankSpaces.test(email))
      return { status: false, message: "O email não pode conter espaços." };
    else if (!regexSpecialCharacters.test(email))
      return { status: false, message: "São aceitos apenas os caracteres especiais: ., _, %, +, $, e -" };

    return { status: regex.test(email), message: "Email inválido" };
  }

  export function getValidCpfCnpjMask(cpfCnpj : string): any {
    cpfCnpj = cpfCnpj.replace(/[^\d]+/g, '');

    if(cpfCnpj.length > 11){
      return '00.000.000/0000-00'
    }
    return '000.000.000-00999'
  }

  export function getValidPhoneMask(phoneValue: string): any {
    if (phoneValue.length >= 13)
      return '+00 (00) 00000-0000';

    return '+00 (00) 0000-00009';
  }

  export function noWhitespaceValidator(text: string): boolean {
    const isWhitespace = (text || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid;
  }
}
