<div class="mask">
    <section class="dropdown-mobile" appClickOutside (clickOutside)="closeDropdown.emit()">
        <header class="dropdown-mobile--header">
            <img class="dropdown-mobile--header--logo" src="/assets/images/b2bbee_logo_mobile.svg" alt="Logo B2Bbee">
            <app-icon (click)="closeDropdown.emit()" src="/assets/icons/close.svg" alt="Ícone para fechar"></app-icon>
        </header>
        <main class="dropdown-mobile--main">
            <ng-container *ngIf="!currentUser; else userLogged">
                <button (click)="redirectTo('/login')">Login</button>
                <button (click)="redirectTo('/register')">Cadastre-se</button>
            </ng-container>
            <ng-template #userLogged>
                <h3>{{ currentUser?.name }}</h3>
                <span>{{ currentUser?.email }}</span>
            </ng-template>
        </main>
        <nav class="dropdown-mobile--nav">
            <ng-container *ngIf="!currentUser; else userLoggedNav">
                <ul>
                    <li><a (click)="redirectTo('/companies')">Empresas</a></li>
                    <li><a (click)="redirectTo('/products')">Produtos</a></li>
                    <li><a (click)="redirectTo('/login')">Anuncie seu negócio</a></li>
                    <li><a (click)="redirectTo('/about')">Quem somos</a></li>
                    <li><a (click)="redirectTo('/help')">Ajuda</a></li>
                </ul>
            </ng-container>
            <ng-template #userLoggedNav>
                <ul>
                    <li><img src="/assets/icons/user.svg" alt="Ícone de usuário"><a (click)="redirectTo('/profile/account-data')">Meu cadastro</a></li>
                    <li><a (click)="redirectTo('/profile/company-data')">Minha empresa</a></li>
                    <li><a (click)="redirectTo('/profile/my-ads')">Meus anúncios</a></li>
                    <li><a (click)="redirectTo('/profile/my-orders')">Histórico de pedidos</a></li>
                    <li><a (click)="redirectTo('/plans')">Plano B2Bbee Pay</a></li>
                    <li><a (click)="redirectTo('/create-ad')">Anuncie o seu negócio</a></li>
                </ul>
            </ng-template>
        </nav>
        <footer class="dropdown-mobile--footer" *ngIf="currentUser">
            <img src="/assets/icons/logout.svg" alt="Ícone de Logout">
            <span (click)="logout.emit()">Sair</span>
        </footer>
    </section>
</div>
