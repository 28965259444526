//#region Imports

import { Injectable } from '@angular/core';
import { Location } from '../../models/interfaces/location.interface';
import { AddressInteractor } from '../../interactors/address/address.interactor';
import { CreateAddressPayload } from '../../models/payloads/create-address.payload';
import { UpdateAddressPayload } from '../../models/payloads/update-address.payload';
import { AddressProxy } from '../../models/proxies/address.proxy';
import { CrudRequestParams } from '../../utils/crud-options';
import { ErrorService } from '../error/error.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AddressService {

  //#region Constructor

  constructor(
    private readonly interactor: AddressInteractor,
    private readonly errorService: ErrorService
  ) {}

  //#endregion

  //#region Methods
  public async get(crudOptions?: CrudRequestParams<AddressProxy>): Promise<AddressProxy[]> {
    const { success, error } = await this.interactor.getAll(crudOptions);

    if (!success)
      throw this.errorService.getErrorMessage(error);

    return Array.isArray(success) ? success : success.data;
  }

  public async getCitiesRegistered(crudOptions?: CrudRequestParams<AddressProxy>): Promise<string[]> {
    const { success, error } = await this.interactor.getCitiesRegistered();

    if (!success)
      throw this.errorService.getErrorMessage(error);

    return success;
  }

  public async getOne(id: number, crudOptions?: CrudRequestParams<AddressProxy>): Promise<AddressProxy> {
    const { success, error } = await this.interactor.getOne(id, crudOptions);

    if (!success)
      throw this.errorService.getErrorMessage(error);

    return success;
  }

  public async update(id: number, payload: Partial<UpdateAddressPayload>): Promise<AddressProxy> {

    const { success, error } = await this.interactor.update(id, payload);

    if (!success)
      throw this.errorService.getErrorMessage(error);

    return success;
  }

  public async create(payload: CreateAddressPayload): Promise<AddressProxy> {

    const { success: createdAddress, error } = await this.interactor.create(payload);

    if (!createdAddress)
      throw this.errorService.getErrorMessage(error);

    return createdAddress;
  }

  public async getCurrentLocation(lat: number, long: number): Promise<Location> {
    const { success, error } = await this.interactor.getCurrentLocation(lat, long);

    if (!success)
      throw this.errorService.getErrorMessage(error);

    return success;
  }

  //#endregion

}
