import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoryProxy } from '../../models/proxies/category.proxy';

@Component({
  selector: 'app-dropdown-search-mobile',
  templateUrl: './dropdown-search-mobile.component.html',
  styleUrls: ['./dropdown-search-mobile.component.scss']
})
export class DropdownSearchMobileComponent {

  @Output()
  public sendWordFiltered : EventEmitter<string> = new EventEmitter<string>();

  public selectedCategory?: CategoryProxy
  ;

  public selectedLocation?: string;

  public wordFiltered?: string;

  public receivedLocationFilter(event: string): void {
    this.selectedLocation = event || undefined;
  }

  public receivedCategoryFilter(event: CategoryProxy): void {
    this.selectedCategory = event || undefined;
  }

  public receivedWordFilter(event: string): void {
    this.wordFiltered = event || undefined;
    this.searchWord();
  }

  public searchWord(): void {
    this.sendWordFiltered.emit(this.wordFiltered)
  }
}
