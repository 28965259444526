import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectLocationComponent } from './select-location.component';
import { ClickOutsiteModule } from '../../directives/click-outsite/click-outsite.module';



@NgModule({
  imports: [
    CommonModule,
    ClickOutsiteModule
  ],
  declarations: [
    SelectLocationComponent
  ],
  exports: [
    SelectLocationComponent
  ]
})
export class SelectLocationModule { }
