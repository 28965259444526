<section class="dropdown-user" appClickOutside (clickOutside)="closeDropdown.emit()">
    <header class="dropdown-user--header">
        <h3>{{ currentUser?.name }}</h3>
        <span>{{ currentUser?.email }}</span>
    </header>
    <nav class="dropdown-user--menu">
        <ul>
            <li><a (click)="redirectTo('/profile/account-data')" ><img src="/assets/icons/user.svg" alt="Ícone de usuário">Meu cadastro</a></li>
            <hr>
            <li><a (click)="redirectTo('/profile/company-data')">Minha empresa</a></li>
            <li><a (click)="redirectTo('/profile/my-ads')">Meus anúncios</a></li>
            <li><a (click)="redirectTo('/profile/my-orders')">Histórico de pedidos</a></li>
            <li><a (click)="redirectTo('/plans')">Plano B2Bbee Pay</a></li>
            <li><a (click)="redirectTo('/create-ad')">Anuncie o seu negócio</a></li>
        </ul>
    </nav>
    <hr>
    <footer class="dropdown-user--footer" (click)="logout.emit()">
        <img src="/assets/icons/logout.svg" alt="Ícone de Logout">
        <span>Sair</span>
    </footer>
</section>
