// #region Imports

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastTypesEnum } from '../../models/enums/toast-types.enum';
import { CategoryProxy } from '../../models/proxies/category.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ToastService } from '../../services/toast/toast.service';
import { UserService } from '../../services/user/user.service';

// #endregion

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  // #region Constructor

  constructor(
    private readonly userService: UserService,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly toastService: ToastService
  ) {
    this.userSubscription = this.userService.getUser$()?.subscribe((user) => {
      if (user) this.currentUser = user;
    });
  }

  // #endregion

  // #region Private properties

  private readonly userSubscription: Subscription;

  // #endregion

  // #region Public properties

  public currentUser: UserProxy | null = null;

  public isLogged: boolean = false;

  public userLogged: boolean = false;

  public openDropdownUser: boolean = false;

  public openDropdownMobile: boolean = false;

  public openSearchMobile: boolean = false;

  public isLoading: boolean = false;

  public selectedCategory?: CategoryProxy;

  public selectedLocation?: string;

  public wordFiltered?: string;

  public toastTypeEnum: typeof ToastTypesEnum = ToastTypesEnum;

  // #endregion

  // #region Public methods

  public async performLogout(): Promise<void> {
    if (this.isLoading) return;

    this.isLoading = true;

    try {
      await this.authenticationService.logout();

      await this.reloadComponent();
    } catch (error) {
      this.toastService.showToast(this.toastTypeEnum.ERROR, error);
    } finally {
      this.isLoading = false;
    }
  }

  public receivedLocationFilter(event: string): void {
    this.selectedLocation = event || undefined;
  }

  public receivedCategoryFilter(event: CategoryProxy): void {
    this.selectedCategory = event || undefined;
  }

  public receivedWordFilter(event: string): void {
    this.wordFiltered = event || undefined;
  }

  // #endregion

  // #region Private methods

  private reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  // #endregion  
}
