import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoryProxy } from '../../models/proxies/category.proxy';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  constructor(
    private readonly activatedRoute: ActivatedRoute
  ){
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.word = queryParams['textSearch'];
    });
  }

  @Output()
  public wordFiltered: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public hasButton: boolean = false;

  @Input()
  public selectedCategory?: CategoryProxy;

  @Input()
  public selectedLocation?: string;

  public word: string = '';

  public searchWord(): void {
    this.wordFiltered.emit(this.word)
  }

}
