import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserProxy } from '../../models/proxies/user.proxy';

@Component({
  selector: 'app-dropdown-mobile',
  templateUrl: './dropdown-mobile.component.html',
  styleUrls: ['./dropdown-mobile.component.scss']
})
export class DropdownMobileComponent {

  constructor(
    private readonly router: Router
  ){}

  @Output()
  public logout: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public closeDropdown: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public currentUser?: UserProxy | null;

  public redirectTo(route: string): void {
    this.router.navigateByUrl(route);
    this.closeDropdown.emit();
  }

}
