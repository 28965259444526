<section class="select-categories" appClickOutside (clickOutside)="openDropdown = false">
    <label (click)="openDropdown = !openDropdown" class="select-categories--label"
        [class.select-categories--second-label]="!labelBackgroundActive">
        {{ !categoryActive ? 'Categorias' : categoryActive?.title }}
        <app-icon [class.rotate-arrow]="openDropdown" src="/assets/icons/arrow.svg"></app-icon>
    </label>
    <ul *ngIf="openDropdown" class="select-categories--dropdown"
        [class.select-categories--second-dropdown]="!labelBackgroundActive">
        <li *ngFor="let item of categories" (click)="selectCategory(item)"
            [class.active-category]="categoryActive?.id === item.id">{{ item.title }}</li>
    </ul>
</section>