//#region Imports

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomValidators } from '../../utils/validators';

import { environment } from '../../../environments/environment';
import { AuthenticationInteractor } from '../../interactors/authentication/authentication.interactor';
import { LoginPayload } from '../../models/payloads/login.payload';
import { TokenProxy } from '../../models/proxies/token.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import { ErrorService } from '../error/error.service';
import { StorageService } from '../storage/storage.service';
import { UserService } from '../user/user.service';
import { RolesEnum } from '../../models/enums/roles.enum';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  //#region Constructor

  constructor(
    private readonly userService: UserService,
    private readonly errorService: ErrorService,
    private readonly interactor: AuthenticationInteractor,
    private readonly storageService: StorageService,
    private readonly router: Router,
  ) { }

  //#endregion

  //#region Public properties

  public isUserAdminOrInvalid = false;

  //#endregion

  //#region Methods

  public async getUserFromStorage(): Promise<UserProxy | null> {
    const { success, error } = await this.storageService.get<UserProxy>(environment.storageKeys.userInformation);

    if (!success)
      throw new Error(error?.message);

    return success;
  }

  public async getUserTokenFromStorage(): Promise<TokenProxy | null> {
    const { error, success } = await this.storageService.get<TokenProxy>(environment.storageKeys.userToken);

    if (!success)
      throw new Error(error?.message);

    return success;
  }

  public isLogged(): boolean {
    return !!this.userService.getUser();
  }

  public async logout(): Promise<void> {
    await this.storageService.clear();

    this.userService.clearUser();

    if(!this.isUserAdminOrInvalid){
      this.router.navigate(['home']).then(() => {
        window.location.reload();
      });
    }

    this.isUserAdminOrInvalid = false;
  }

  public async login(payload: LoginPayload): Promise<UserProxy | null> {
    const isInvalidEmail = !CustomValidators.isValidEmail(payload.username);
    const isInvalidPassword = !CustomValidators.isValidPassword(payload.password);

    if (isInvalidEmail || isInvalidPassword)
      throw this.errorService.getErrorMessage('E-mail ou Senha inválidos');

    const { error, success: tokenProxy } = await this.interactor.login(payload);

    if (error)
      throw this.errorService.getErrorMessage(error);

    await this.saveUserToken(tokenProxy);

    const user = this.userService.getUser();

    if(user){
      if(this.isUserAdmin(user)){
        this.isUserAdminOrInvalid = true;
        this.logout();
        throw this.errorService.getErrorMessage('Você não possui permissão para realizar essa ação.');
      }

      if(!user.isEmailVerified){
        this.isUserAdminOrInvalid = true;
        this.logout();
        throw this.errorService.getErrorMessage('Antes de realizar o login valide seu e-mail.');
      }
    }

    return user;
  }

  private async saveUserToken(token?: TokenProxy): Promise<void> {
    const { error: errorOnSaveToken } = await this.storageService.set(environment.storageKeys.userToken, token);

    if (errorOnSaveToken)
      throw new Error(errorOnSaveToken.message);

    await this.getUserInformationAndUpdateLoggedUser();
  }

  public async getUserInformationAndUpdateLoggedUser(): Promise<void> {
    const { error: errorUser, success: user } = await this.interactor.getMe();

    if (errorUser) {
      await this.storageService.clear();
      throw this.errorService.getErrorMessage(errorUser);
    }

    try {
      if (user)
        await this.updateLoggedUser(user);
    } catch (error) {
      throw this.errorService.getErrorMessage(error);
    }
  }

  public async updateLoggedUser(user: UserProxy): Promise<void> {
    const { error: errorOnSaveUser } = await this.storageService.set(environment.storageKeys.userInformation, user);

    if (errorOnSaveUser) {
      await this.storageService.clear();
      throw new Error(errorOnSaveUser.message);
    }

    this.userService.setUser(user);
  }

  public isUserAdmin(user : UserProxy): boolean {
      return user?.roles.includes(RolesEnum.ADMIN)
  }

  //#endregion

}
