//#region Imports

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { firstValueFrom, Observable, Subject } from 'rxjs';

import { AsyncResult } from '../models/async-result';
import { DefaultOptions, ExtraOptions } from '../models/http-options';

//#endregion

@Injectable()
export class HttpService {

  //#region Constructor

  constructor(
    protected readonly http: HttpClient,
  ) { }

  //#endregion

  //#region Properties

  private readonly onHttpError: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();

  //#endregion

  //#region Methods

  public getOnHttpError$(): Observable<HttpErrorResponse> {
    return this.onHttpError.asObservable();
  }

  public getHttpClient(): HttpClient {
    return this.http;
  }

  public async get<T>(
    url: string,
    options?: DefaultOptions,
  ): Promise<AsyncResult<T>> {
    return await firstValueFrom(this.http.get<T>(url, options))
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }

  public async post<T>(
    url: string,
    payload: object,
    options?: ExtraOptions,
  ): Promise<AsyncResult<T>> {
    return await firstValueFrom(this.http.post<T>(url, payload, options))
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }

  public async put<T>(
    url: string,
    payload: object,
    options?: ExtraOptions,
  ): Promise<AsyncResult<T>> {
    return await firstValueFrom(this.http.put<T>(url, payload, options))
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }

  public async patch<T>(
    url: string,
    payload: object,
    options?: ExtraOptions,
  ): Promise<AsyncResult<T>> {
    return await firstValueFrom(this.http.patch<T>(url, payload, options))
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }

  public async delete<T>(
    url: string,
    options?: ExtraOptions,
  ): Promise<AsyncResult<T>> {
    return await firstValueFrom(this.http.delete<T>(url, options))
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }

  private success<T>(result: T): AsyncResult<T> {
    return {
      success: result,
    };
  }

  private error<T>(error: HttpErrorResponse): AsyncResult<T> {
    this.onHttpError.next(error);

    return {
      error,
    };
  }

  //#endregion

}
