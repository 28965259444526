import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { SelectLocationModule } from '../select-location/select-location.module';
import { SelectCategoriesModule } from '../select-categories/select-categories.module';
import { SearchModule } from '../search/search.module';
import { DropdownUserModule } from '../dropdown-user/dropdown-user.module';
import { DropdownMobileModule } from '../dropdown-mobile/dropdown-mobile.module';
import { DropdownSearchMobileModule } from '../dropdown-search-mobile/dropdown-search-mobile.module';
import { RouterModule } from '@angular/router';
import { ClickOutsiteModule } from '../../directives/click-outsite/click-outsite.module';

@NgModule({
  imports: [
    CommonModule,
    SelectLocationModule,
    SelectCategoriesModule,
    SearchModule,
    DropdownUserModule,
    DropdownMobileModule,
    DropdownSearchMobileModule,
    RouterModule,
    ClickOutsiteModule
  ],
  declarations: [
    HeaderComponent
  ],
  exports: [
    HeaderComponent
  ]
})

export class HeaderModule { }
