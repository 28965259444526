//#region Imports

import { Injectable } from '@angular/core';
import { CategoryInteractor } from '../../interactors/category/category.interactor';
import { CrudRequestParams } from '../../utils/crud-options';
import { ErrorService } from '../error/error.service';
import { CategoryProxy } from '../../models/proxies/category.proxy';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class CategoryService {

  //#region Constructor

  constructor(
    private readonly interactor: CategoryInteractor,
    private readonly errorService: ErrorService
  ) {}

  //#endregion

  //#region Methods

  public async getAll(crudOptions?: CrudRequestParams<CategoryProxy>): Promise<CategoryProxy[]> {
    const { success, error } = await this.interactor.getAll(crudOptions);

    if (!success)
      throw this.errorService.getErrorMessage(error);

    return Array.isArray(success) ? success : success.data;
  }

  //#endregion

}
