//#region Imports

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '../services/storage/storage.service';
import { environment } from '../../environments/environment';
import { TokenProxy } from '../models/proxies/token.proxy';
import { UserProxy } from '../models/proxies/user.proxy';
import { UserService } from '../services/user/user.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {

  //#region Constructor

  constructor(
    private readonly router: Router,
    private readonly storage: StorageService,
    private readonly userService: UserService
  ) { }

  //#endregion

  //#region Methods

  public async canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot) {
    const { unprotectedRoute, protectedRoute, routeToRedirect } = route.data || {};

    const hasToken = await this.storage.get<TokenProxy>(environment.storageKeys.userToken).then(result => !!result.success);

    await this.handleUser(hasToken);

    if (!routeToRedirect)
      return true;

    if (hasToken && protectedRoute)
      return true;

    if (!hasToken && unprotectedRoute)
      return true;

    return await this.router.navigate([routeToRedirect], { replaceUrl: true }) && true;
  }

  private async handleUser(token: boolean): Promise<void> {

    const { success: userStorage } = await this.storage.get<UserProxy>(environment.storageKeys.userInformation);

    if (userStorage)
      this.userService.setUser(userStorage);

    if (token && !userStorage){
      const user = await this.userService.getMe();
      this.userService.setUser(user);
    }

  }


  //#endregion
}
