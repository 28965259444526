import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownSearchMobileComponent } from './dropdown-search-mobile.component';
import { SelectLocationModule } from '../select-location/select-location.module';
import { SelectCategoriesModule } from '../select-categories/select-categories.module';
import { SearchModule } from '../search/search.module';



@NgModule({
  imports: [
    CommonModule,
    SelectLocationModule,
    SelectCategoriesModule,
    SearchModule
  ],
  declarations: [ DropdownSearchMobileComponent ],
  exports: [ DropdownSearchMobileComponent ]
})
export class DropdownSearchMobileModule { }
