//#region Imports

import { Injectable } from '@angular/core';
import { GetManyProxy } from '../../models/proxies/base/get-many.proxy';
import { CategoryProxy } from '../../models/proxies/category.proxy';
import { AsyncResult } from '../../modules/http/models/async-result';
import { createCrudUrl, CrudRequestParams } from '../../utils/crud-options';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../modules/http/services/http.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class CategoryInteractor {

  //#region Constructor

  constructor(
    private readonly httpService: HttpService,
  ) { }

  //#endregion

  //#region Methods

  public async getOne(id: number, crudOptions?: CrudRequestParams<CategoryProxy>): Promise<AsyncResult<CategoryProxy>> {
    const url = createCrudUrl<CategoryProxy>(environment.apiKeys.address.byId.replace('{categoryId}',  String(id)), crudOptions);

    return await this.httpService.get<CategoryProxy>(url);
  }

  public async getAll(crudOptions?: CrudRequestParams<CategoryProxy>): Promise<GetManyProxy<CategoryProxy>> {
    const url = createCrudUrl<CategoryProxy>(environment.apiKeys.category.base, crudOptions);

    return await this.httpService.get<CategoryProxy[]>(url);
  }

  //#endregion
}
