<header class="header">
    <img class="header--mobile--menu-collapse" src="/assets/icons/menu_collapse.svg" alt="Ícone de Menu"
        (click)="openDropdownMobile = !openDropdownMobile">
    <img class="header--logo" src="/assets/images/b2bbee_logo.svg" alt="Logo B2Bbee" routerLink="/home">
    <img class="header--mobile--search" src="/assets/icons/search_brown.svg" alt="Ícone de Busca"
        (click)="openSearchMobile = !openSearchMobile">
    <div class="header--content">
        <ng-container class="header--mobile">
            <app-dropdown-mobile *ngIf="openDropdownMobile" [currentUser]="currentUser" (logout)="performLogout()" (closeDropdown)="openDropdownMobile = false">
            </app-dropdown-mobile>
            <ng-container *ngIf="openSearchMobile">
                <app-dropdown-search-mobile (sendWordFiltered)="receivedWordFilter($event)"></app-dropdown-search-mobile>
            </ng-container>
        </ng-container>
        <section class="header--content--top">
            <app-select-location (selectedLocation)="receivedLocationFilter($event)"></app-select-location>
            <app-select-categories [labelBackgroundActive]="true" (selectedCategory)="receivedCategoryFilter($event)">
            </app-select-categories>
            <app-search (wordFiltered)="receivedWordFilter($event)"></app-search>
            <button class="header--content--top--button" routerLink="/products"
                [queryParams]="{textSearch: wordFiltered, categoryId: selectedCategory?.id, city: selectedLocation}">Pesquisar</button>
            <div class="header--content--top--access" *ngIf="!currentUser; else userLogged">
                <button routerLink="/register">Cadastre-se</button>
                <button routerLink="/login">Login</button>
            </div>
            <ng-template #userLogged>
                <div class="header--content--top--logged">
                    <button routerLink="/profile/my-ads">Meus Anúncios</button>
                    <section class="header--content--top--logged--profile"
                        (click)="openDropdownUser = !openDropdownUser">
                        <div class="header--content--top--logged--profile-avatar">
                            <img src="/assets/icons/user_default.svg" alt="Ícone de usuário">
                        </div>
                        <img class="header--content--top--logged--profile-arrow" src="/assets/icons/arrow_filled.svg"
                            alt="Ícone de seta">
                    </section>

                    <ng-container *ngIf="openDropdownUser">
                        <app-dropdown-user [currentUser]="currentUser" (logout)="performLogout()" (closeDropdown)="openDropdownUser = false"></app-dropdown-user>
                    </ng-container>
                </div>
            </ng-template>
        </section>
        <nav class="header--content--bottom">
            <ul>
                <li><a routerLink="/companies">Empresas</a></li>
                <li><a routerLink="/products">Serviços e Produtos</a></li>
                <li><a [routerLink]="currentUser ? ['/create-ad'] : ['/login']"
                  >Anuncie seu negócio</a></li>
                <li><a routerLink="/about">Quem somos</a></li>
                <li><a routerLink="/help">Ajuda</a></li>
            </ul>
        </nav>
    </div>
</header>
