import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastTypesEnum } from '../../models/enums/toast-types.enum';
import { CategoryProxy } from '../../models/proxies/category.proxy';
import { CategoryService } from '../../services/category/category.service';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'app-select-categories',
  templateUrl: './select-categories.component.html',
  styleUrls: ['./select-categories.component.scss'],
})
export class SelectCategoriesComponent implements OnInit {

  constructor(
    private readonly categoryService: CategoryService,
    private readonly toastService: ToastService,
    private readonly activatedRoute: ActivatedRoute
    ) {
      this.activatedRoute.queryParams.subscribe((queryParams) => {
        this.categoryId = queryParams['categoryId'];
      });
    }

  @Input()
  public labelBackgroundActive?: boolean = false;

  @Output()
  public selectedCategory: EventEmitter<CategoryProxy> = new EventEmitter<CategoryProxy>();

  public openDropdown: boolean = false;

  public categoryId?: number;

  public categories: CategoryProxy[] = [];

  public categoryActive?: CategoryProxy;

  public toastTypesEnum: typeof ToastTypesEnum = ToastTypesEnum; 

  public async ngOnInit(): Promise<void> {
    await this.loadCategories();

    if (this.categoryId)
      this.categoryActive = this.categories.find(category => category.id == Number(this.categoryId));
  }

  public async loadCategories(): Promise<void> {
    try {
      this.categories = await this.categoryService.getAll();
    } catch (error) {
      this.toastService.showToast(this.toastTypesEnum.ERROR, error);
    }
  }

  public selectCategory(category: CategoryProxy): void {
    this.categoryActive = category;
    this.selectedCategory.emit(category);

    this.openDropdown = false;
  }
}
