<footer class="footer">
  <article class="footer--nav">
    <section class="footer--nav--tags">
      <nav class="footer--nav--tags--company">
        <h5 class="footer--nav--tags--company--title">A EMPRESA</h5>
        <hr />
        <ul>
          <li>
            <a class="footer--nav--tags--company--link" routerLink="/about"
              >Quem somos</a
            >
          </li>
          <li>
            <a class="footer--nav--tags--company--link" routerLink="/contact-us">Contato</a>
          </li>
          <li>
            <a class="footer--nav--tags--company--link" routerLink="/privacy-policy"
              >Política de privacidade</a
            >
          </li>
          <li>
            <a class="footer--nav--tags--company--link" routerLink="/terms-of-use"
              >Termos e Condições</a
            >
          </li>
        </ul>
      </nav>
      <nav class="footer--nav--tags--providers">
        <h5 class="footer--nav--tags--company--title">PARA FORNECEDORES</h5>
        <hr />
        <ul>
          <li>
            <a class="footer--nav--tags--company--link" [routerLink]="currentUser ? ['/create-ad'] : ['/login']"
              >Anuncie seu Negócio</a
            >
          </li>
        </ul>
      </nav>
      <nav class="footer--nav--tags--companies">
        <h5 class="footer--nav--tags--company--title">PARA EMPRESAS</h5>
        <hr />
        <ul>
          <li>
            <a class="footer--nav--tags--company--link" routerLink="/companies"
              >Explore as Categorias</a
            >
          </li>
          <li>
            <a class="footer--nav--tags--company--link" routerLink="/products"
              >Produtos em destaque</a
            >
          </li>
          <li>
            <a class="footer--nav--tags--company--link" routerLink="/companies"
              >Fornecedores em destaque</a
            >
          </li>
        </ul>
      </nav>

      <nav class="footer--nav--tags--social">
        <div class="footer--nav--tags--social--medias">
          <a href="https://www.facebook.com/" target="_blank">
            <app-icon src="/assets/icons/facebook.svg"></app-icon>
          </a>
          <a href="https://web.whatsapp.com/" target="_blank">
            <app-icon src="/assets/icons/whatsapp.svg" alt="ícone-whatsapp"></app-icon>
          </a>
          <a href="https://www.instagram.com/" target="_blank">
            <app-icon src="/assets/icons/instagram.svg" alt="ícone-instagram"></app-icon>
          </a>
          <a href="https://www.linkedin.com/" target="_blank">
            <app-icon src="/assets/icons/linkedin.svg" alt="ícone-linkedin"></app-icon>
          </a>
          <a href="mailto:b2bbee@facens.com.br">
            <app-icon src="/assets/icons/email.svg" alt="ícone-email"></app-icon>
          </a>
        </div>

        <h6 class="footer--nav--tags--social--contact">
          Entre em contato com a gente
        </h6>
        <a class="footer--nav--tags--social--contact--email" href="mailto:b2bbee@facens.com.br">b2bbee@facens.com.br</a>
      </nav>
    </section>
  </article>
  <div class="footer--copyright">
    <img
      class="footer--copyright--img"
      src="/assets/icons/copyright.svg"
      alt="ícone-copyright"
    />
    <p class="footer--copyright--text">
      {{ year.getFullYear() }}. Todos os direitos reservados para a B2BBEE. Versão web: {{ version }}
      <span *ngIf="!isProduction && !isStaging">Versão API: {{ apiVersion?.version }}</span>
    </p>
  </div>

  <div class="footer--image">
    <img src="/assets/images/losang.svg" alt="imagem-losangulo" />
  </div>
</footer>
