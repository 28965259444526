import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from 'src/app/models/interfaces/location.interface';
import { ToastTypesEnum } from '../../models/enums/toast-types.enum';
import { AddressService } from '../../services/address/address.service';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss']
})
export class SelectLocationComponent implements OnInit{

  constructor(
    private readonly addressService: AddressService,
    private readonly toastService: ToastService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    activatedRoute.queryParams.subscribe((queryParams) => {
      this.locationActive = queryParams['city'];
    });
  }

  @Output()
  public selectedLocation: EventEmitter<string> = new EventEmitter<string>();

  public openDropdown: boolean = false;

  public locations: Array<string> = [];

  public locationActive?: string = '';

  public toastTypesEnum: typeof ToastTypesEnum = ToastTypesEnum;

  public location: Location = {
    place_id: 0,
    licence: "",
    osm_type: "",
    osm_id: 0,
    lat: "",
    lon: "",
    place_rank: 0,
    category: "",
    type: "",
    importance: 0,
    addresstype: "",
    name: "",
    display_name: "",
    address: {
      road: "",
      quarter: "",
      suburb: "",
      city_district: "",
      city: "",
      municipality: "",
      county: "",
      state_district: "",
      state: "",
      ISO3166_2_lvl4: "",
      region: "",
      postcode: "",
      country: "",
      country_code: ""
    },
    boundingbox: [
      ""
    ]
  }

  public async ngOnInit(): Promise<void> {
    await this.loadCitiesRegistered();
    this.location = await this.getCurrentLocation();
    this.locationActive = this.location.address.city;
  }

  public getCurrentLocation(): Promise<Location> {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(Error('No support for geolocation'));
        return;
      }

      navigator.geolocation.getCurrentPosition((position) => {
        const result = this.addressService.getCurrentLocation(position.coords.latitude, position.coords.longitude);
        resolve(result);
      });
    });
  }

  public async loadCitiesRegistered(): Promise<void> {
    try {
      this.locations = await this.addressService.getCitiesRegistered();
    } catch (error) {
      this.toastService.showToast(this.toastTypesEnum.ERROR, error);
    }
  }

  public selectLocation(location: string): void {
    this.locationActive = location;
    this.selectedLocation.emit(location);

    this.openDropdown = false;
  }

}
