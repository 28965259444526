import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { IconModule } from "../icon/icon.module";
import { FooterComponent } from "./footer.component";


@NgModule({
    declarations: [
        FooterComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        IconModule,
    ],
    exports: [
        FooterComponent
    ]
})
export class FooterModule { }