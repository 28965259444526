<section class="select-location" appClickOutside (clickOutside)="openDropdown = false">
    <label (click)="openDropdown = !openDropdown" class="select-location--label">
        <img src="/assets/icons/pin.svg" alt="Icone de pin">
        {{ locationActive ? locationActive : 'Localização' }}
    </label>
    <ul *ngIf="openDropdown" class="select-location--dropdown">
        <li *ngFor="let item of locations" [class.active-location]="locationActive === item"
            (click)="selectLocation(item)">
            <img src="/assets/icons/pin_white.svg" alt="Icone de pin">{{ item }}
        </li>
    </ul>
</section>
