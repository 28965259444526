import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsiteDirective } from './click-outsite.directive';



@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ClickOutsiteDirective],
  exports: [ClickOutsiteDirective]
})
export class ClickOutsiteModule { }
