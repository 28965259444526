import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage-angular';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StorageDrivers } from './models/enums/storage-drivers.enum';
import { HttpModule } from './modules/http/http.module';
import { BaseUrlInterceptor } from './modules/http/interceptors/base-url.interceptor';
import { BearerTokenInterceptor } from './modules/http/interceptors/bearer-token.interceptor';
import { RefreshTokenInterceptor } from './modules/http/interceptors/refresh-token.interceptor';
import { RetryInterceptor } from './modules/http/interceptors/retry.interceptor';
import { MainModule } from './pages/main/main.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpModule.withConfig({
      baseUrl: environment.apiBaseUrl,
      bearerTokenKey: environment.storageKeys.userToken,
    }),
    IonicStorageModule.forRoot({
      dbKey: '__b2bbeedb_key',
      storeName: '__b2bbeedb',
      driverOrder: [
        StorageDrivers.SQLITE,
        StorageDrivers.IndexedDB,
        StorageDrivers.LocalStorage,
      ],
    }),
    MainModule,
    HttpClientModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BearerTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
