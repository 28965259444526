//#region Imports

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastTypesEnum } from '../../models/enums/toast-types.enum';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor() {}

  public toastTypesEnum: typeof ToastTypesEnum = ToastTypesEnum;

  public showsToast$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public toastMessage$: BehaviorSubject<string> = new BehaviorSubject<string>('Mensagem padrão');

  public toastState$: BehaviorSubject<ToastTypesEnum> = new BehaviorSubject<ToastTypesEnum>(ToastTypesEnum.INFO);

  public showToast(type: ToastTypesEnum, message: any): void {
    this.toastState$.next(type);

    this.toastMessage$.next(message);

    this.showsToast$.next(true);

    setTimeout(() => {
      this.dismissToast();
    }, 3000);
  }

  public dismissToast(): void {
    this.showsToast$.next(false);
  }

  //#endregion
}
