//#region Imports

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChangePasswordPayload } from '../../models/payloads/change-password.payload';
import { ForgotPasswordPayload } from '../../models/payloads/forgot-password.payload';
import { CrudRequestParams } from '../../utils/crud-options';
import { environment } from '../../../environments/environment';
import { UserInteractor } from '../../interactors/user/user.interactor';
import { CreateUserPayload } from '../../models/payloads/create-user.payload';
import { UpdateUserPayload } from '../../models/payloads/update-user.payload';
import { UserProxy } from '../../models/proxies/user.proxy';
import { ErrorService } from '../error/error.service';
import { StorageService } from '../storage/storage.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class UserService {

  //#region Constructor

  constructor(
    private readonly interactor: UserInteractor,
    private readonly errorService: ErrorService,
    private readonly storageService: StorageService
  ) { }

  //#endregion

  //#region Properties

  private readonly userSubject: BehaviorSubject<UserProxy | null> = new BehaviorSubject<UserProxy | null>(null);

  //#endregion

  //#region Methods

  public getUser(): UserProxy | null {
    return this.userSubject.getValue();
  }

  public getUser$(): Observable<UserProxy | null> {
    return this.userSubject.asObservable();
  }

  public setUser(user: UserProxy): void {
    this.userSubject.next(user);
    this.storageService.set(environment.storageKeys.userInformation, user);
  }

  public clearUser(): void {
    this.userSubject.next(null);
  }

  public async get(crudOptions?: CrudRequestParams<UserProxy>): Promise<UserProxy[]> {
    const { success, error } = await this.interactor.getAll(crudOptions);

    if (!success)
      throw this.errorService.getErrorMessage(error);

    return Array.isArray(success) ? success : success.data;
  }

  public async getMe(): Promise<UserProxy> {
    const { success, error } = await this.interactor.getMe();

    if (!success)
      throw this.errorService.getErrorMessage(error);

    return success;
  }

  public async update(id: number, payload: Partial<UpdateUserPayload>): Promise<UserProxy> {

    const { success, error } = await this.interactor.update(id, payload);

    if (!success)
      throw this.errorService.getErrorMessage(error);

    return success;
  }

  public async create(payload: CreateUserPayload): Promise<UserProxy> {

    const { success: createdUser, error } = await this.interactor.create(payload);

    if (!createdUser)
      throw this.errorService.getErrorMessage(error);

    return createdUser;
  }

  public async delete(id: number, password: string): Promise<void> {

    const { error } = await this.interactor.delete(id, password);

    if (error)
      throw this.errorService.getErrorMessage(error);

    return;
  }

  public async sendEmail(email: string): Promise<ForgotPasswordPayload> {

    const { success, error } = await this.interactor.sendEmail(email);

    if (!success)
      throw this.errorService.getErrorMessage(error);

    return success;
  }

  public async resetPassword(payload: ChangePasswordPayload, code: string): Promise<void> {

    const { success, error } = await this.interactor.resetPassword(payload, code);

    if (error)
      throw this.errorService.getErrorMessage(error);
  }

  public async changePassword(payload: ChangePasswordPayload): Promise<void> {
    const { success, error } = await this.interactor.changePassword(payload);

    if (error)
      throw this.errorService.getErrorMessage(error);
  }

  public async verifyAccount(code: string): Promise<void> {
    const { success, error } = await this.interactor.verifyAccount(code);

    if(error)
      throw this.errorService.getErrorMessage(error);
  }
  //#endregion

}
