import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserProxy } from '../../models/proxies/user.proxy';
import { VersionProxy } from '../../models/proxies/version.proxy';
import { UserService } from '../../services/user/user.service';
import { VersionService } from '../../services/version/version.service';
import { environment } from '../../../environments/environment';
import { version } from '../../../environments/version';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private readonly userService: UserService,
    private readonly versionService: VersionService,
  ){
    this.userSubscription = this.userService.getUser$()?.subscribe((user) => {
      if (user) this.currentUser = user;
    });
  }

  private readonly userSubscription: Subscription;

  public version: string = version;

  public currentUser : UserProxy | null = null;

  public apiVersion?: VersionProxy;

  public year : Date = new Date;

  public isProduction  = environment.production;

  public isStaging  = environment.staging;

  public async ngOnInit(): Promise<void> {
    if (this.isProduction || this.isStaging)
      return;

    this.apiVersion = await this.versionService.get();
  }
}
