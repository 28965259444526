import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthenticationGuard } from './guards/authentication.guard';

export const unAuthenticatedRoute = { canActivate: [AuthenticationGuard], data: { unprotectedRoute: true } };
export const authenticatedRoute = { canActivate: [AuthenticationGuard], data: { routeToRedirect: environment.config.redirectToWhenAuthenticated, protectedRoute: true } };

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: "login", loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)},
  { path: "register", loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule)},
  { path: "forgot-password", loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)},
  { path: "forgot-password/change-password/:code/:userId", loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)},
  { path: "users/verify/:code", loadChildren: () => import('./pages/verified-account/verified-account.module').then(m => m.VerifiedAccountModule)},
  { path: '*', loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
