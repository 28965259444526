//#region Imports

import { Injectable } from '@angular/core';
import { VersionInteractor } from '../../interactors/version/version.interactor';
import { VersionProxy } from '../../models/proxies/version.proxy';
import { ErrorService } from '../error/error.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class VersionService {

  //#region Constructor

  constructor(
    private readonly interactor: VersionInteractor,
    private readonly errorService: ErrorService
  ) {}

  //#endregion

  //#region Methods

  public async get(): Promise<VersionProxy> {
    const { success, error } = await this.interactor.get();

    if (!success)
      throw this.errorService.getErrorMessage(error);

    return success;
  }

  //#endregion

}
