<section class="search-mobile">
    <div class="search-mobile--top">
        <app-select-location
        (selectedLocation)="receivedLocationFilter($event)"></app-select-location>
        <app-select-categories [labelBackgroundActive]="true"
        (selectedCategory)="receivedCategoryFilter($event)"></app-select-categories>
    </div>
    <app-search [hasButton]="true"
    (wordFiltered)="receivedWordFilter($event)"
    [selectedCategory]="selectedCategory"
    [selectedLocation]="selectedLocation"></app-search>
</section>
