import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '../icon/icon.module';
import { ToastComponent } from './toast.component';



@NgModule({
  imports: [
    CommonModule,
    IconModule,
  ],
  declarations: [ToastComponent],
  exports: [ToastComponent]
})
export class ToastModule { }
