//#region Imports

import { Injectable } from '@angular/core';
import { VersionProxy } from '../../models/proxies/version.proxy';
import { createCrudUrl } from '../../utils/crud-options';
import { environment } from '../../../environments/environment';
import { AsyncResult } from '../../modules/http/models/async-result';
import { HttpService } from '../../modules/http/services/http.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class VersionInteractor {

  //#region Constructor

  constructor(
    private readonly httpService: HttpService,
  ) { }

  //#endregion

  //#region Methods

  public async get(): Promise<AsyncResult<VersionProxy>> {
    const url = createCrudUrl<VersionProxy>(environment.apiKeys.version.base);

    return await this.httpService.get(url);
  }

  //#endregion
}
