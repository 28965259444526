//#region Imports

import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

//#endregion

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements AfterViewInit, OnChanges {

  //#region Public Properties

  @ViewChild('iconElement')
  public iconElement!: ElementRef;

  @Input()
  public style!: Partial<CSSStyleDeclaration>;

  @Input()
  public src?: string;

  public isAfterViewInit: boolean = false;

  //#endregion

  //#region LifeCycle

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.isAfterViewInit && this.src) {
      await this.renderSvg();
    }
    else {
      if (this.iconElement)
        this.iconElement.nativeElement.innerHTML = '';
    }
  }

  public async ngAfterViewInit(): Promise<void> {
    if (this.src)
      await this.renderSvg();
    this.isAfterViewInit = true;
  }

  //#endregion

  //#region Public methods

  public async renderSvg(): Promise<void> {
    if (!this.src)
      return

    this.iconElement.nativeElement.innerHTML = await fetch(this.src)
      .then(async (response) => {
        return await response.text();
      });
  }

  //#endregion
}
