import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authenticatedRoute, unAuthenticatedRoute } from '../../app-routing.module';
import { FooterModule } from '../../components/footer/footer.module';
import { HeaderModule } from '../../components/header/header.module';
import { ToastModule } from '../../components/toast/toast.module';
import { AuthenticationGuard } from '../../guards/authentication.guard';
import { MainComponent } from './main.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: "home", loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [AuthenticationGuard] },
      { path: "companies", loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule), ...unAuthenticatedRoute },
      { path: "products", loadChildren: () => import('./products/products.module').then(m => m.ProductsModule), ...unAuthenticatedRoute },
      { path: "about", loadChildren: () => import('./about/about.module').then(m => m.AboutModule), ...unAuthenticatedRoute },
      { path: "contact-us", loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule), ...unAuthenticatedRoute },
      { path: "products/:id/details", loadChildren: () => import('./products/product-details/product.module').then(m => m.ProductModule) },
      { path: "profile", redirectTo: "profile/account-data" },
      { path: "profile/account-data",loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule), ...authenticatedRoute },
      { path: "profile/company-data",loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule), ...authenticatedRoute },
      { path: "profile/my-ads",loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule), ...authenticatedRoute },
      { path: "profile/my-orders",loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule), ...authenticatedRoute },
      { path: "companies/:id/details", loadChildren: () => import('./companies/company-details/company-details.module').then(m => m.CompanyDetailsModule), ...unAuthenticatedRoute },
      { path: "companies/:id/details/products-list", loadChildren: () => import('./products/products.module').then(m => m.ProductsModule), ...unAuthenticatedRoute },
      { path: "privacy-policy", loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule), ...unAuthenticatedRoute },
      { path: "terms-of-use", loadChildren: () => import('./terms-of-use/terms-of-use.module').then(m => m.TermsOfUseModule), ...unAuthenticatedRoute },
      { path: "plans", loadChildren: () => import('./plans/plans.module').then(m => m.PlansModule), ...authenticatedRoute },
      { path: "create-ad/plans", loadChildren: () => import('./plans/plans.module').then(m => m.PlansModule), ...authenticatedRoute },
      { path: "create-ad", loadChildren: () => import('./create-ad/create-ad.module').then(m => m.CreateAdModule), ...authenticatedRoute },
      { path: "edit-ad/:id", loadChildren: () => import('./create-ad/create-ad.module').then(m => m.CreateAdModule), ...authenticatedRoute },
      { path: "help", loadChildren: () => import('./help/help.module').then(m => m.HelpModule), ...unAuthenticatedRoute },
      { path: "placeholder", loadChildren: () => import('./placeholder/placeholder.module').then(m => m.PlaceholderModule), ...unAuthenticatedRoute },
      { path: "payment", loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule), ...authenticatedRoute },
      { path: "complaint", loadChildren: () => import('./complaint/complaint.module').then(m => m.ComplaintModule), ...authenticatedRoute },
      { path: '**', redirectTo: 'home' },
    ]
  }
];


@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    ToastModule,
    RouterModule.forChild(routes)
  ],
  exports: [MainComponent]
})
export class MainModule {}
