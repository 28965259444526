import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { ToastTypesEnum, toastTypesEnumToColorRecord, toastTypesEnumToIconRecord, toastTypesEnumToTitleRecord } from '../../models/enums/toast-types.enum';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('toastTrigger', [
      state('open', style({ transform: 'translateY(0%)' })),
      state('close', style({ transform: 'translateY(-200%)' })),
      transition('open <=> close', [animate('300ms ease-in-out')]),
    ]),
  ],
})
export class ToastComponent {

  constructor(
    public toast: ToastService
    ) {}

  public showsToast: boolean = false;

  public toastTypesEnum: typeof ToastTypesEnum = ToastTypesEnum;

  public toastTypesEnumToTitle: Record<ToastTypesEnum, string> = toastTypesEnumToTitleRecord;

  public toastTypesEnumToColor: Record<ToastTypesEnum, string> = toastTypesEnumToColorRecord;

  public toastTypesEnumToIcon: Record<ToastTypesEnum, string> = toastTypesEnumToIconRecord;

  public dismiss(): void {
    this.toast.dismissToast();
  }
}
