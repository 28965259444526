export enum ToastTypesEnum {
  SUCCESS = 1,
  INFO = 2,
  WARNING = 3,
  ERROR = 4,
  NONE = 5,
}

export const toastTypesEnumToTitleRecord: Record<ToastTypesEnum, string> = {
  [ToastTypesEnum.SUCCESS]: 'Sucesso!',
  [ToastTypesEnum.INFO]: 'Informação!',
  [ToastTypesEnum.WARNING]: 'Mensagem de atenção!',
  [ToastTypesEnum.ERROR]: 'Mensagem de erro!',
  [ToastTypesEnum.NONE]: '',
};

export const toastTypesEnumToColorRecord: Record<ToastTypesEnum, string> = {
  [ToastTypesEnum.SUCCESS]: 'var(--color-green-200)',
  [ToastTypesEnum.INFO]: 'var(--color-blue-300)',
  [ToastTypesEnum.WARNING]: 'var(--color-orange-300)',
  [ToastTypesEnum.ERROR]: 'var(--color-red-200)',
  [ToastTypesEnum.NONE]: ''
};

export const toastTypesEnumToIconRecord: Record<ToastTypesEnum, string> = {
  [ToastTypesEnum.SUCCESS]: '/assets/icons/success-toast.svg',
  [ToastTypesEnum.INFO]: '/assets/icons/info-toast.svg',
  [ToastTypesEnum.WARNING]: '/assets/icons/warning-toast.svg',
  [ToastTypesEnum.ERROR]: '/assets/icons/error-toast.svg',
  [ToastTypesEnum.NONE]: ''
};


